export const VERSION = '1.0.0-beta';

import { getAnalytics, logEvent } from "firebase/analytics";
import { app } from "../firebase/firebaseConfig"
const analytics = getAnalytics(app);





export function getVersion() {
    return VERSION;
}



export function isNaN(value) {
    Number.isNaN(Number(value));
}



export function getCurrentTimeStamp(t1) {
    let t = "";

    const hh = t1.getHours() < 10 ? `0${t1.getHours()}` : t1.getHours();
    const min = t1.getMinutes() < 10 ? `0${t1.getMinutes()}` : t1.getMinutes();
    const ss = t1.getSeconds() < 10 ? `0${t1.getSeconds()}` : t1.getSeconds();
    const ms = t1.getMilliseconds();

    t = `${hh
        }:${min
        }:${ss}`;

    return t;
}


export function log4j(target, msg) {

    const result = _fncList.find(({ name }) => name === target)
    if (result) {
        if (result.status === 'Y') {
            const _target = `${getCurrentTimeStamp()}::${target}=>`;
        }
    }
}


export function log4jEvent(eventName, parameter) {
    logEvent(analytics, eventName, parameter);
}

export function showError(payload) {
    payload.notification({
        title: '에러',
        text: payload.msg,
        color: "#f44336",
        icon: '<i class="fa-regular fa-circle-exclamation"></i>',
        position: "top-right"
    });
}

export function showSuccess(payload) {
    payload.notification({
        title: '메세지',
        text: payload.msg,
        color: "#0264fb",
        icon: '<i class="fa-regular fa-circle-check"></i>',
        position: "top-right"
    });
}

export function getCurrentTime2(val) {
    let t = "";

    const t1 = new Date(val);

    const yyyy = t1.getFullYear().toString();
    const mm = (t1.getMonth() + 1).toString();
    const dd = t1.getDate().toString();
    const hh = t1.getHours() < 10 ? `0${t1.getHours()}` : t1.getHours();
    const min = t1.getMinutes() < 10 ? `0${t1.getMinutes()}` : t1.getMinutes();
    const ss = t1.getSeconds() < 10 ? `0${t1.getSeconds()}` : t1.getSeconds();

    t = `${yyyy
        }/${mm[1] ? mm : `0${mm[0]}`
        }/${dd[1] ? dd : `0${dd[0]}`
        } ${hh
        }:${min
        }:${ss}`;

    return t;
}

export function getCurrentTime3(val) {
    let t = "";

    const t1 = new Date(val);

    const yyyy = t1.getFullYear().toString();
    const mm = (t1.getMonth() + 1).toString();
    const dd = t1.getDate().toString();

    t = `${yyyy
        }년 ${mm[1] ? mm : `0${mm[0]}`
        }월 ${dd[1] ? dd : `0${dd[0]}`
        }일`;

    return t;
}

export function getCurrentTime4(val) {
    let t = "";

    const t1 = new Date(val);

    const yyyy = t1.getFullYear().toString();
    const mm = (t1.getMonth() + 1).toString();
    const dd = t1.getDate().toString();
    const hh = t1.getHours() < 10 ? `0${t1.getHours()}` : t1.getHours();
    const min = t1.getMinutes() < 10 ? `0${t1.getMinutes()}` : t1.getMinutes();


    t = `${yyyy
        }년 ${mm[1] ? mm : `0${mm[0]}`
        }월 ${dd[1] ? dd : `0${dd[0]}`
        }일 ${hh}:${min}`;

    return t;
}


export function getCurrentHour() {
    const t1 = new Date();
    return t1.getHours();
}

export function formatNumber(value) {
    if (value) {
        value += "";
        const list = value.split(".");
        const prefix = list[0].charAt(0) === "-" ? "-" : "";
        let num = prefix ? list[0].slice(1) : list[0];
        let result = "";
        while (num.length > 3) {
            result = `,${num.slice(-3)}${result}`;
            num = num.slice(0, num.length - 3);
        }
        if (num) {
            result = num + result;
        }
        return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`;
    }
}




export function getCurrentTime(t1) {
    let t = "";
    const yyyy = t1.getFullYear().toString();
    const mm = (t1.getMonth() + 1).toString();
    const dd = t1.getDate().toString();
    const hh = t1.getHours() < 10 ? `0${t1.getHours()}` : t1.getHours();
    const min = t1.getMinutes() < 10 ? `0${t1.getMinutes()}` : t1.getMinutes();
    const ss = t1.getSeconds() < 10 ? `0${t1.getSeconds()}` : t1.getSeconds();

    t = `${yyyy
        }/${mm[1] ? mm : `0${mm[0]}`
        }/${dd[1] ? dd : `0${dd[0]}`
        } ${hh
        }:${min
        }:${ss}`;

    return t;
}


export function dateDiffInDays(b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const a = new Date();
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function getCurrentDate(val) {
    let t = "";

    const t1 = val;

    const yyyy = t1.getFullYear().toString();
    const mm = (t1.getMonth() + 1).toString();
    const dd = t1.getDate().toString();

    t = `${yyyy}/${mm[1] ? mm : `0${mm[0]}`}/${dd[1] ? dd : `0${dd[0]}`}`;

    return t;
}


export function getCurrentDateSimple(val) {
    let t = "";

    const t1 = val;

    const mm = (t1.getMonth() + 1).toString();
    const dd = t1.getDate().toString();

    t = `${mm[1] ? mm : `0${mm[0]}`}${dd[1] ? dd : `0${dd[0]}`}`;

    return t;
}


export function getCurrentDate2(val) {
    let t = "";

    const t1 = val;

    const yyyy = t1.getFullYear().toString();
    const mm = (t1.getMonth() + 1).toString();
    const dd = t1.getDate().toString();
    const hh = t1.getHours() < 10 ? `0${t1.getHours()}` : t1.getHours();

    t = `'${yyyy.substr(2)}/${mm[1] ? mm : `0${mm[0]}`}/${dd[1] ? dd : `0${dd[0]}`} ${hh}`;

    return t;
}



export function getHex(str) {
    let result = "";
    for (let i = 0; i < str.length; i++) {
        result += str.charCodeAt(i).toString(16);
    }
    return result;
}

export function getCurrentTimeNew() {
    let t = "";

    const t1 = new Date();

    const yyyy = t1.getFullYear().toString();
    const mm = (t1.getMonth() + 1).toString();
    const dd = t1.getDate().toString();
    const hh = t1.getHours() < 10 ? `0${t1.getHours()}` : t1.getHours();
    const min = t1.getMinutes() < 10 ? `0${t1.getMinutes()}` : t1.getMinutes();
    const ss = t1.getSeconds() < 10 ? `0${t1.getSeconds()}` : t1.getSeconds();

    t = `${yyyy
        }/${mm[1] ? mm : `0${mm[0]}`
        }/${dd[1] ? dd : `0${dd[0]}`
        } ${hh
        }:${min
        }:${ss}`;

    return t;
}


export function getCurrentDate3(val) {
    let t = "";

    const t1 = val;

    const yyyy = t1.getFullYear().toString();
    const mm = (t1.getMonth() + 1).toString();
    const dd = t1.getDate().toString();

    t = yyyy + (mm[1] ? mm : `0${mm[0]}`) + (dd[1] ? dd : `0${dd[0]}`);

    return t;
}

export function getCurrentDate4(val) {
    let t = "";

    const t1 = val;

    const yy = t1.getFullYear().toString().substr(2);
    const mm = (t1.getMonth() + 1).toString();
    const dd = t1.getDate().toString();

    t = yy + '.' + (mm[1] ? mm : `0${mm[0]}`) + '.' + (dd[1] ? dd : `0${dd[0]}`);

    return t;
}


export function getCurrentDate5(val) {
    let t = "";

    const t1 = new Date(val);

    const yyyy = t1.getFullYear().toString();
    const mm = (t1.getMonth() + 1).toString();
    const dd = t1.getDate().toString();
    const hh = t1.getHours() < 10 ? `0${t1.getHours()}` : t1.getHours();
    const min = t1.getMinutes() < 10 ? `0${t1.getMinutes()}` : t1.getMinutes();
    const ss = t1.getSeconds() < 10 ? `0${t1.getSeconds()}` : t1.getSeconds();

    t = `${yyyy
        }${mm[1] ? mm : `0${mm[0]}`
        }${dd[1] ? dd : `0${dd[0]}`
        }${hh
        }${min
        }${ss}`;

    return t;
}

export function getCurrentDate6(val) {
    let t = "";

    const t1 = val;

    const yyyy = t1.getFullYear().toString();
    const mm = (t1.getMonth() + 1).toString();
    const dd = t1.getDate().toString();

    t = yyyy + '.' + (mm[1] ? mm : '0' + mm[0]) + '.' + (dd[1] ? dd : '0' + dd[0]);

    return t;
}


export function getCurrentDate3From(val) {
    let t = "";

    const t1 = val;

    const yyyy = t1.getFullYear().toString();
    const mm = (t1.getMonth() + 1).toString();
    const dd = t1.getDate().toString();

    t = `${yyyy}/${mm[1] ? mm : `0${mm[0]}`}/${dd[1] ? dd : `0${dd[0]}`} 00:00:00`;

    return t;
}


export function getCurrentDate3To(val) {
    let t = "";

    const t1 = val;

    const yyyy = t1.getFullYear().toString();
    const mm = (t1.getMonth() + 1).toString();
    const dd = t1.getDate().toString();

    t = `${yyyy}/${mm[1] ? mm : `0${mm[0]}`}/${dd[1] ? dd : `0${dd[0]}`} 23:59:59`;

    return t;
}

export function replaceAll(str, searchStr, replaceStr) {
    return str.split(searchStr).join(replaceStr);
}

export function getCurrentDay(val) {
    let t = "";

    const t1 = val;

    const yyyy = t1.getFullYear().toString();
    const mm = (t1.getMonth() + 1).toString();
    const dd = t1.getDate().toString();

    t = `${yyyy}.${mm[1] ? mm : `0${mm[0]}`}.${dd[1] ? dd : `0${dd[0]}`}`;

    return t;
}


